var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base" },
    [
      _c("demand-filter", { on: { change: _vm.onChange } }),
      _c("div", { staticClass: "list" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.onReachBottom,
                expression: "onReachBottom"
              }
            ],
            attrs: {
              "infinite-scroll-disabled": "loading",
              "infinite-scroll-distance": "10"
            }
          },
          _vm._l(_vm.list, function(item, index) {
            return _c("demand-item", {
              key: index,
              attrs: { role: _vm.type, entity: item }
            })
          }),
          1
        ),
        !_vm.loading
          ? _c("div", { staticClass: "nodata" }, [_vm._v("已无更多数据")])
          : _vm._e(),
        !_vm.isEnd && _vm.loading
          ? _c(
              "div",
              { staticClass: "loading" },
              [
                _c("mt-spinner", { attrs: { color: "#178CF9", type: "snake" } })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }