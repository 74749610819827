<template>
  <div class="filter">
    <div class="filter-nav" :style="{ zIndex: 9999 }" v-if="showSearch">
      <a @click="onOpen('type')" class="filter-item" href="javascript:;">
        <span>需求类型</span>
        <img src="~@/assets/img/project/icon_down_gray@2x.png" alt="" />
      </a>
      <a @click="onOpen('time')" class="filter-item" href="javascript:;">
        <span>选择时间</span>
        <img src="~@/assets/img/project/icon_down_gray@2x.png" alt="" />
      </a>
      <div class="filter-search" @click="showSearchBtu">
        <img src="~@/assets/img/project/icon_news_search@2x.png" alt="" />
      </div>
    </div>
    <div class="filter-nav" v-else>
      <div class="infotDetai">
        <form action="javascript:return true">
          <div class="search">
            <img src="@/assets/img/publish/icon_news_search.png" />
            <input type="search" v-model.trim="searchKey" @keydown="searchTo($event)" placeholder="请输入需求标题" />
            <img @click="closeClick" src="@/assets/img/information/icon_close.png" />
          </div>
        </form>
      </div>
      <div class="filter-search" @click="showSearchBtu">
        <img src="~@/assets/img/project/screening.png" alt="" />
      </div>
    </div>
    <!-- 类型选择 -->
    <mt-popup v-model="typeVisible" position="top">
      <div class="typelist">
        <div class="typelist-divider"></div>
        <div v-for="(item, index) in typeList" :key="index" class="typelist-card">
          <label v-if="item.label">{{ item.label }}</label>
          <ul>
            <li
              @click="onSelectType(item2)"
              :class="[typeValue === item2.value && 'active', item2.empty && 'empty']"
              v-for="(item2, index2) in item.items"
              :key="index2"
            >
              {{ item2.title }}
            </li>
          </ul>
        </div>
      </div>
    </mt-popup>
    <!-- 时间选择 -->
    <mt-popup v-model="timeVisible" position="top">
      <div class="typelist">
        <div class="typelist-divider"></div>
        <div class="typelist-card">
          <label>选择时间</label>
          <div class="time-input">
            <div @click="onOpenTime('start')" class="time-input__btn">
              <!-- <input v-model="startTimeStr" placeholder="开始时间" type="text" disabled/>-->
              <p class="time_cell time_pla_cell" v-if="!startTimeStr">开始时间</p>
              <p class="time_cell" v-else>{{ startTimeStr }}</p>
            </div>
            <span class="time-input__divider"></span>
            <div @click="onOpenTime('end')" class="time-input__btn">
              <p class="time_cell time_pla_cell" v-if="!endTimeStr">结束时间</p>
              <p class="time_cell" v-else>{{ endTimeStr }}</p>
              <!-- <input v-model="endTimeStr" placeholder="结束时间" type="text" disabled />-->
            </div>
          </div>
          <mt-button @click="onConfirmTime" class="confirm-btn" type="primary">确定</mt-button>
        </div>
      </div>
    </mt-popup>
    <mt-datetime-picker
      type="date"
      ref="startPicker"
      year-format="{value} 年"
      month-format="{value} 月"
      day-format="{value} 日"
      @confirm="onConfirmStartTime"
      v-model="startTime"
    >
    </mt-datetime-picker>
    <mt-datetime-picker
      type="date"
      ref="endPicker"
      year-format="{value} 年"
      month-format="{value} 月"
      day-format="{value} 日"
      @confirm="onConfirmEndTime"
      v-model="endTime"
    >
    </mt-datetime-picker>
  </div>
</template>
<script>
import moment from 'moment'
import { listDictData } from '@/api/enterprise'

const dateFormatter = 'YYYY-MM-DD'

export default {
  components: {},
  data() {
    return {
      showSearch: true,
      typeVisible: false,
      timeVisible: false,
      typeValue: 0,
      timeValue: 0,
      typeList: [
        {
          label: '',
          items: [{ title: '全部类型', value: 0 }]
        },
        {
          label: '企业服务',
          items: []
        }
      ],
      timeList: [
        {
          label: '',
          items: [
            { title: '全部', value: 0 },
            { title: '本周', value: 1 },
            { title: '本月', value: 2 },
            { empty: true }
          ]
        }
      ],
      startTime: new Date(),
      endTime: new Date(),
      startTimeStr: '',
      endTimeStr: '',
      searchKey: ''
    }
  },
  mounted() {
    this.loadConfig()
  },
  methods: {
    onOpenTime(type) {
      // console.log('点击了', type)
      this.$refs[`${type}Picker`].open()
      // this.$refs.startPicker.open()
    },
    onConfirmStartTime() {
      this.startTimeStr = moment(this.startTime).format('YYYY年MM月DD日')
    },
    onConfirmEndTime() {
      this.endTimeStr = moment(this.endTime).format('YYYY年MM月DD日')
    },
    onConfirmTime() {
      if (this.startTimeStr && this.endTimeStr) {
        this.onChange()
      } else {
        this.onClose()
      }
    },
    async loadConfig() {
      const resList = await listDictData({ code: 'project_type' })
      const items = resList.map(({ title, value }) => {
        return {
          title: title,
          value: value
        }
      })
      const num = items.length % 4
      if (num !== 0) {
        for (let i = 0; i < 4 - num; i++) {
          items.push({ empty: true })
        }
      }
      this.typeList[1].items = items
    },
    onSelectType(item) {
      this.typeValue = item.value
      this.onChange()
    },
    onSelectTime(item) {
      this.timeValue = item.value
      this.onChange()
    },
    onChange() {
      const up = {}
      if (this.typeValue !== 0) {
        up['type'] = this.typeValue
      }
      if (this.startTimeStr && this.endTimeStr) {
        up['timeStart'] = moment(this.startTime).format(dateFormatter)
        up['timeEnd'] = moment(this.endTime).format(dateFormatter)
      }
      if (this.searchKey) {
        up['title'] = this.searchKey
      }
      // if (this.timeValue === 1) {
      //   up['timeStart'] = moment().startOf('week').format(dateFormatter)
      //   up['timeEnd'] = moment().endOf('week').format(dateFormatter)
      // }
      // if (this.timeValue === 2) {
      //   up['timeStart'] = moment().startOf('month').format(dateFormatter)
      //   up['timeEnd'] = moment().endOf('month').format(dateFormatter)
      // }
      this.$emit('change', up)
      this.onClose()
    },
    onOpen(name) {
      if (name === 'type') {
        this.typeVisible = true
        this.timeVisible = false
        this.$refs[`startPicker`].close()
        this.$refs[`endPicker`].close()
      } else {
        this.typeVisible = false
        this.timeVisible = true
      }
    },
    onClose() {
      this.typeVisible = false
      this.timeVisible = false
    },
    showSearchBtu() {
      this.typeValue = 0
      this.startTimeStr = ''
      this.endTimeStr = ''
      this.showSearch = !this.showSearch
      this.searchKey = ''
      this.typeVisible = false
      this.timeVisible = false
      this.$refs[`startPicker`].close()
      this.$refs[`endPicker`].close()
      this.onChange()
    },
    searchTo(event) {
      this.typeValue = 0
      this.startTimeStr = ''
      this.endTimeStr = ''
      this.onChange()
      if (event.keyCode === 13) {
        this.allLoaded = false
      }
    },
    closeClick() {
      this.close = false
      this.searchKey = ''
      this.onChange()
    }
  }
}
</script>
<style lang="less" scoped>
.filter {
  height: 44px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;

  &-nav {
    padding-right: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(255, 255, 255, 1);
  }

  &-item {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 16px;
      background: rgba(240, 240, 240, 1);
      right: 0;
      position: absolute;
    }

    &:nth-child(2) {
      &::after {
        display: none;
      }
    }

    img {
      width: 9px;
      height: 6px;
      margin-left: 6px;
    }

    span {
      font-size: 15px;
    }
  }

  &-search {
    position: absolute;
    height: 44px;
    width: 60px;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: -1px -3px 3px 0px rgba(0, 0, 0, 0.1);

    img {
      width: 16px;
      height: 16px;
    }
  }

  .typelist {
    width: 100vw;
    background-color: white;
    transform: translateY(44px);
    padding-bottom: 20px;

    &-divider {
      height: 7px;
      background-color: #f5f5f5;
    }

    &-card {
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      label {
        font-size: 12px;
        color: #666666;
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        margin-top: -12px;

        li {
          width: 23%;
          height: 37px;
          background: rgba(245, 245, 245, 1);
          border-radius: 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          margin-top: 12px;

          &.active {
            background: rgba(231, 243, 254, 1);
            color: #178cf9;
          }

          &.empty {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  .time-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
    margin-top: 10px;
    border-bottom: 1px solid #e5e5e5;

    &__btn {
      height: 35px;
      width: 120px;

      input {
        height: 35px;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border: none;
        outline: none;
        text-align: center;
        font-size: 14px;
      }

      .time_cell {
        height: 35px;
        width: 120px;
        text-align: center;
        font-size: 14px;
        line-height: 35px;
      }

      .time_pla_cell {
        color: #d3d3d8;
      }
    }

    &__divider {
      height: 1px;
      width: 20px;
      background-color: #e5e5e5;
      margin: 0 5px;
    }
  }

  .confirm-btn {
    background-color: #178cf9;
  }
}

.infotDetai {
  .search {
    height: 36px;
    overflow: hidden;
    line-height: 36px;
    padding: 0px 18.5px;
    background: #f3f3f3;
    border-radius: 30px;
    vertical-align: middle;

    img {
      vertical-align: middle;
      padding-right: 0.5px;
    }

    input {
      border: none;
      width: 235px;
      background: #f3f3f3;
      vertical-align: middle;
      font-size: 15px;
      color: #333;
    }

    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button {
      display: none;
    }
  }
}
</style>
