var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.entity.auditStatus !== "4"
    ? _c("div", { staticClass: "item", on: { click: _vm.onJumpDetail } }, [
        _c("div", { staticClass: "item-title" }, [
          _vm._v(_vm._s(_vm.entity.title))
        ]),
        _c(
          "div",
          { staticClass: "item-info" },
          [
            _c(
              "span",
              {
                staticClass: "item-tag",
                style: {
                  color: _vm.statusObj.color,
                  backgroundColor: _vm.statusObj.bgColor
                }
              },
              [_vm._v(_vm._s(_vm.statusObj.title))]
            ),
            _vm._l(_vm.typeList, function(item, index) {
              return _c("span", { key: index, staticClass: "item-tag" }, [
                _vm._v(_vm._s(item))
              ])
            }),
            _c("span", { staticClass: "item-tag" }, [
              _vm._v(
                " " +
                  _vm._s(String(_vm.entity.createTime).slice(0, 10)) +
                  " - " +
                  _vm._s(String(_vm.entity.endTime).slice(0, 10)) +
                  " "
              )
            ])
          ],
          2
        ),
        _vm.isAdmin &&
        (_vm.role === "company" || _vm.role === "personal") &&
        _vm.entity.status < 3
          ? _c("div", { staticClass: "item-action" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.onEdit($event)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/project/icon_edit@2x.png"),
                      alt: ""
                    }
                  }),
                  _c("span", [_vm._v("编辑需求")])
                ]
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }