<template>
  <div class="base">
    <demand-filter @change="onChange"></demand-filter>
    <div class="list">
      <div v-infinite-scroll="onReachBottom" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
        <demand-item :role="type" :entity="item" v-for="(item, index) in list" :key="index"></demand-item>
      </div>
      <div v-if="!loading" class="nodata">已无更多数据</div>
      <div v-if="!isEnd && loading" class="loading">
        <mt-spinner color="#178CF9" type="snake"></mt-spinner>
      </div>
    </div>
  </div>
</template>
<script>
import DemandFilter from './components/DemandFilter'
import DemandItem from './components/DemandItem'
import { listProjectData } from '@/api/enterprise'
import { mapGetters } from 'vuex'

export default {
  components: {
    DemandFilter,
    DemandItem
  },
  data() {
    return {
      loading: false,
      pageNo: 0,
      isEnd: false,
      list: [],
      options: {}
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['type', 'userInfo'])
  },
  methods: {
    onChange(json) {
      this.options = json
      this.refresh()
    },
    refresh() {
      this.isEnd = false
      this.list = []
      this.loadData(1)
    },
    async loadData(pageNo) {
      this.loading = true
      try {
        const { success, result } = await listProjectData({
          pageSize: 10,
          pageNo,
          auditStatus: 1,
           orgType: this.userInfo.type,
          companyId: this.userInfo.companyId,
          ...this.options
        })
        const totalPage = result.pages
        if (success) {
          if (+pageNo === 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
          this.pageNo = pageNo
          if (this.pageNo >= totalPage) {
            this.isEnd = true
          }
        }
      } catch (error) {
        this.isEnd = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      if (this.isEnd) return
      this.loadData(this.pageNo + 1)
    }
  }
}
</script>
<style lang="less" scoped>
.base {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 80px;

  .list {
    padding: 12px 15px;
    margin-top: 44px;

    .loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .nodata {
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #666666;
    }
  }
}
</style>
